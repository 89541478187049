import React, { useState, useEffect } from "react";
import "./Tile.css";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import {Buffer} from 'buffer';

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { MapView } from "./MapView";

import Divider from "@mui/material/Divider";
import { autocompleteClasses } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { faSun } from '@fortawesome/free-solid-svg-icons'
import { faCloudRain} from '@fortawesome/free-solid-svg-icons'

{/* <FontAwesomeIcon icon="fa-solid fa-cloud-fog" /> */}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 800 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#C3E0D4" : "#308fe8",
  },
}));

// const modalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 800,
//   height: "69vh",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   borderRadius: "15px",
//   overflow: "hidden",
//   overflowY: "scroll",
//   outline: "none !important",
//   // p: 4,
// };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: "200px",
  borderRadius: "15px",
  "&:hover": {
    background: "#90BEAB",
    cursor: "pointer",
  },
}));

function Tiles() {
  const [show, setShow] = useState(false);
  const [tile, setTile] = useState();
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [weather,setWeather] = useState()
  const [weatherIcon, setWeatherIcon] = useState()

  // Fetch Weather

  const getWeather = (lat,lng) => {
    const openMeteoURL = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current_weather=true`
    fetch(openMeteoURL)
    .then((res) => res.json())
    .then(info => {
      const current = info.current_weather
      setWeather(current?.temperature)
      const weatherCode = current?.weathercode.toString()
      getWeatherIcon(weatherCode)
    })
    .catch((err) => console.log({ error: err.message }));
  }

  const getWeatherIcon = (weatherCode) =>{
    const number = JSON.parse(weatherCode)
    if(number == 0){
      setWeatherIcon(faSun)
    }
    if(number > 0 && number < 4){
      setWeatherIcon(faCloud)
    }
    if(number === 45 && number === 48){
      setWeatherIcon(faCloud)
    }
    if(number > 50 && number < 67){
      setWeatherIcon(faCloudRain)
    }
    console.log(number,"NUMBER")
    console.log(weatherIcon,"WEATHER CODE")
  }

  // Get Cities
  // fsq363eIRSoE2de0Ll5ehLCra5/83YtyeOXCzDIo9aXczBM=

  // const getCities = () => {
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       Authorization: 'fsq363eIRSoE2de0Ll5ehLCra5/83YtyeOXCzDIo9aXczBM='
  //     }
  //   };
    
  //   fetch('https://api.foursquare.com/v3/places/fsq_id', options)
  //     .then(response => response.json())
  //     .then(response => console.log(response))
  //     .catch(err => console.error(err));
  // }

  // useEffect(()=>{
  //   getCities()
  // },[])

  return (
    <div class="tileContainer">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {tileData.map((value, index) => (
            <Grid
              onMouseOver={() => {
                setShow(true);
                setTile(index);
                setData(value);
              }}
              onMouseOut={() => {
                setShow(false)
              }}
              item
              xs={2}
              sm={4}
              md={4}
              key={index}
            >
              <Item
                onClick={() => 
                  {setShowModal(true)
                  getWeather(value.position[0].toString(),value.position[1].toString())}
                }
                style={{
                  backgroundColor: "black",
                  backgroundImage: `url(${value?.img})`,
                  backgroundPosition: `center center `,
                  position: `relative`,
                }}
              >
                {!show && (
                  <h3
                  className="locationTitle"
                  >
                    {value?.location}
                  </h3>
                )}
                {show && index === tile && (
                  <Box
                    style={{
                      height: "90%",
                      borderRadius: "15px",
                      color: "white",
                      padding: "20px",
                      paddingTop: "0px",
                      backgroundColor: "rgb(0,0,0, 0.6)",
                    }}
                    className="locationBackground"
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      textAlign="left"
                      paddingTop="10px"
                    >
                      {value?.items.slice(0, 4).map((value, index) => (
                        <>
                          <Grid style={{ paddingTop: "10px" }} item xs={6}>
                            <p>{value.name}</p>
                          </Grid>
                          <Grid
                            style={{ textAlign: "left", paddingTop: "10px" }}
                            item
                            xs={6}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={value.values}
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Item>
            </Grid>
          ))}
          <Modal
            open={showModal}
            onClose={() => 
            {setShowModal(false)
            getWeather("","")}
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ backgroundColor: "rgba(144,190,171, 0.8)" }}
          >
            <Box className="modalStyle">
              <div
                style={{
                  backgroundImage: `url(${data?.img})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize:"cover",
                  height: "20%",
                  borderRadius: "15px",
                  position: "relative",
                  padding:"10px"
                }}
              >
                <p
                style={{color:"white",
              textAlign:"center",
              backdropFilter:'blur(40px)',
            width:"15%"}}
                >{weather}°C   <FontAwesomeIcon icon={weatherIcon} /> 
                </p>
                
                <Typography class="modalLocationTitle">
                  {data?.location}
                </Typography>
              </div>
              <Grid
                container
                direction="row"
                alignItems="center"
                textAlign="left"
                backgroundColor="rgba(195,224,212, 0.2)"
                paddingTop="30px"
              >
                {data?.items.map((value, index) => (
                  <>
                    <Grid
                      className="modalGridName"
                      item
                      xs={12}
                      lg={3}
                    >
                      <p>{value.name}</p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      className="modalProgress"
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={value.values}
                      />
                    </Grid>
                  </>
                ))}
                <Grid className="mapGrid" item xs={12}>
                  <MapView center={data?.position} />
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Box>
    </div>
  );
}

export default Tiles;

const tileData = [
  {
    location: "Paris, France",
    img: "https://i.insider.com/618406cb326b26001834ce7f?width=700",
    position: [48.8566, 2.3522],
    items: [
      {
        name: "👶 Pram",
        values: "40",
      },
      {
        name: "🛝 Playrgounds",
        values: "90",
      },

      {
        name: "🚌 Transport",
        values: "50",
      },
      {
        name: "🥪 Food",
        values: "95",
      },
      {
        name: "🏨 Hotel",
        values: "60",
      },
    ],
  },
  {
    location: "London, England",
    img: "https://www.ft.com/__origami/service/image/v2/images/raw/http%3A%2F%2Fcom.ft.imagepublish.upp-prod-eu.s3.amazonaws.com%2F4d3a6426-35fe-11ea-ac3c-f68c10993b04?fit=scale-down&source=next&width=700",
    position: [51.505, -0.09],
    items: [
      {
        name: "👶 Pram",
        values: "40",
      },
      {
        name: "🛝 Playrgounds",
        values: "90",
      },

      {
        name: "🚌 Transport",
        values: "50",
      },
      {
        name: "🥪 Food",
        values: "95",
      },
      {
        name: "🏨 Hotel",
        values: "60",
      },
    ],
  },
  {
    location: "Sydney, Australia",
    img: "https://www.australia.com/content/australia/en/places/sydney-and-surrounds/guide-to-sydney/jcr:content/image.adapt.800.HIGH.jpg",
    position: [-33.8688, 151.2093],
    items: [
      {
        name: "👶 Pram",
        values: "40",
      },
      {
        name: "🛝 Playrgounds",
        values: "90",
      },

      {
        name: "🚌 Transport",
        values: "50",
      },
      {
        name: "🥪 Food",
        values: "95",
      },
      {
        name: "🏨 Hotel",
        values: "60",
      },
    ],
  },
  {
    location: "Sorrento, Italy",
    img: "https://www.thediscoveriesof.com/wp-content/uploads/2022/05/Sorrento-shutterstock_519559345.jpg",
    position: [40.6263, 14.3758],
    items: [
      {
        name: "👶 Pram",
        values: "40",
      },
      {
        name: "🛝 Playrgounds",
        values: "90",
      },

      {
        name: "🚌 Transport",
        values: "50",
      },
      {
        name: "🥪 Food",
        values: "95",
      },
      {
        name: "🏨 Hotel",
        values: "60",
      },
    ],
  },
  {
    location: "Marseille, France",
    img: "https://ychef.files.bbci.co.uk/976x549/p0bml6l9.jpg",
    position: [43.2965, 5.3698],
    items: [
      {
        name: "👶 Pram",
        values: "40",
      },
      {
        name: "🛝 Playrgounds",
        values: "90",
      },

      {
        name: "🚌 Transport",
        values: "50",
      },
      {
        name: "🥪 Food",
        values: "95",
      },
      {
        name: "🏨 Hotel",
        values: "60",
      },
    ],
  },
  {
    location: "Paris, France",
    img: "https://i.insider.com/618406cb326b26001834ce7f?width=700",
    position: [48.8566, 2.3522],
    items: [
      {
        name: "👶 Pram",
        values: "40",
      },
      {
        name: "🛝 Playrgounds",
        values: "90",
      },

      {
        name: "🚌 Transport",
        values: "50",
      },
      {
        name: "🥪 Food",
        values: "95",
      },
      {
        name: "🏨 Hotel",
        values: "60",
      },
    ],
  },
];
