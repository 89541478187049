import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import './post.css'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "200px",
    borderRadius: "15px",
    "&:hover": {
      background: "#90BEAB",
      cursor: "pointer",
    },
  }));
  
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75vw",
    height: "89vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "15px",
    padding:"30px",
    overflow: "hidden",
    overflowY: "scroll",
    whiteSpace: 'pre-wrap',
    outline: "none !important",
    // p: 4,
  };

export const Post = () => {
  const mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@hello_65741";

  const [profile, setProfile] = useState({
    name: "Candice Galland",
    profileImage: "",
    profileUrl: "",
  });

  const [blog, setBlog] = useState({
    item: [],
    isLoading: true,
    error: null,
  });

  const [test,setTest] = useState()
  const [data,setData] = useState()
  const [showModal,setShowModal] = useState(false)
  const [show, setShow] = useState(false);

  useEffect(() => {
    // if(!blog?.item)
        fetch(mediumURL)
        .then((res) => res.json())
        .then(info => {

          const image = info.feed.image;
          const link = info.feed.link;
          const blogs = info;
          setTest(info.items)
          setProfile((p) => ({ ...p, profileUrl: link, profileImage: image }));
        //   setBlog({ item: info.feed, isLoading: false });
        })
        .catch((err) => setBlog({ error: err.message }));
  }, []);


  const haveBlogs = () => {
    // if(blog.item){
        return test.map((post,index)=>(
            <div>
                <p>{profile.name}</p>
                <p>{post}</p>
            </div>
        ))
    // }
  }

  return (
  <div className="postContainer">
    <h2>Blog Posts</h2>
    <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
    {test && (test && (test.map((blog, index) => {
        
        return (
            <Grid
              onMouseOver={() => {
                setShow(true);
                setData(blog);
              }}
              onMouseOut={() => setShow(false)}
              item
              xs={2}
              sm={4}
              md={4}
              key={index}
            >
                  <Item
                onClick={() => setShowModal(true)}
                style={{
                  backgroundColor: "#90beab",
                  backgroundImage: `url(${blog?.thumbnail})`,
                  backgroundPosition: `center center `,
                  position: `relative`,
                  alignItems:"center",
                  display:"flex"
                  
                }}
              >
                <h1 class="blogTitle">
                {blog.title}
                </h1>
              </Item>
            </Grid>
    
    )})))}
    <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            backgroundColor="#90beab"
            style={{ backgroundColor: "#90beab" }}
          >
            <Box sx={modalStyle}>
              <div
      dangerouslySetInnerHTML={{__html: data?.content}}
    />
            </Box>
          </Modal>
     </Grid>
        </Box>
  </div>
  );
};
