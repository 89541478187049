import React, { useState, useEffect, useRef, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export const MapMarker = ({ position, onClick }) => {
  const handleClick = (e) => {
    if (onClick) onClick();
  };
  return (
    <Marker
      position={position}
      eventHandlers={{
        click: (e) => {
          handleClick(e);
        },
      }}
    ></Marker>
  );
};

export const MapContext = ({
  center,
  zoom,
  markers,
  children,
  onMarkerClick,
}) => {
  const map = useMap();
  const handleClick = (m) => {
    if (onMarkerClick) onMarkerClick(m);
  };
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom]);
  return (
    <>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=8f999b5d-e9bc-4a40-bcab-72b56b3e40fa"
        //   url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      {markers.map((m) => {
        return <MapMarker position={m?.coord} onClick={() => handleClick(m)} />;
      })}
      {children}
    </>
  );
};

export const MapView = ({
  center = [40.7128, 74.006],
  zoom = 13,
  markers = [],
  children,
  onMarkerClick,
}) => {
  return (
    <MapContainer
      style={{ height: "450px", width: "100%" }}
      animate={true}
      center={center}
      zoom={zoom}
      dragging={false}
      doubleClickZoom={false}
      scrollWheelZoom={false}
      attributionControl={false}
      zoomControl={false}
    >
      <MapContext
        center={center}
        zoom={zoom}
        markers={markers}
        onMarkerClick={onMarkerClick}
      >
        {children}
      </MapContext>
    </MapContainer>
  );
};
