import React, { useRef } from "react";
import "./Home.css";
import { Post } from "./post";
import Tiles from "./Tiles";
import logo from "./toddler-vacay.png";

export const Home = () => {
  // Video Source //
  //www.pexels.com/video/aerial-view-of-beautiful-resort-2169880/
  const handleClickScroll = () => {
    const element = document.getElementById("section2");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  https: return (
    <>
      <section class="showcase">
        <header>
          <img class="logo" src={logo} />
        </header>
        {/* <video
          src="https://traversymedia.com/downloads/videos/explore.mp4"
          muted
          loop
          autoplay
        ></video> */}
        <div class="overlay"></div>
        <div class="text">
          <h2>Never Stop </h2>

          <h3>Exploring The World</h3>
          <p>
          Toddler Vacay is a tool that helps parents plan and organize their travels with their toddlers. It provides information on child-friendly destinations, accommodations, and activities in cities around the world. The tool also offers tips and recommendations for traveling with a toddler, such as what to pack and how to keep them entertained on long flights or car rides. With Toddler Vacay, parents can feel more confident and prepared for their vacation, knowing that they have all the information they need to make the trip enjoyable for both themselves and their little ones.
          </p>
          <a className="exploreButton" onClick={handleClickScroll}>
            Explore
          </a>
        </div>
        <ul className="social">
          <li>
            <a href="#">
              <img src="https://i.ibb.co/x7P24fL/facebook.png" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="https://i.ibb.co/Wnxq2Nq/twitter.png" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="https://i.ibb.co/ySwtH4B/instagram.png" />
            </a>
          </li>
        </ul>
      </section>
      <section class="nextBlock">
        <div class="divPad"></div>
        {/* <Tiles /> */}
        <div class="divPad" id="section2"></div>
      </section>
      <section class="blogBlock">
      <Post />
      </section>
    </>
  );
};

{
  /* <div class="menu">
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">News</a></li>
            <li><a href="#">Destination</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>  */
}
