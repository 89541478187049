import React, { useState, useEffect } from "react";
// import './App.css'
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import Typography from "@mui/material/Typography";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { MapView } from "./MapView";

import Divider from "@mui/material/Divider";
import Tiles from "./Tiles";
import { Home } from "./Home";
function App() {

  return (
    <>
      <Home />
      <Tiles />
    </>
  );
}

export default App;
